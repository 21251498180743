<template>
  <section class="relative z-70 flex flex-wrap">
    <button
      id="search-button"
      aria-label="Search Button"
      class="fill-current transition-all pr-4 pl-5 lg:px-6 lg:pl-6.5 lg:pr-6 search-btn"
      @click="onShowSearch()"
    >
      <icon-search />
    </button>
    <button
      id="mobile-menu-btn"
      aria-label="Mobile Menu"
      class="fill-current transition-all px-4 lg:px-6 block lg:hidden"
      @click="toggleMobileMenu()"
    >
      <icon-menu v-if="open === false" />
      <icon-close v-else />
    </button>
  
    <search
      v-if="showSearch === true"
      :show-search="showSearch"
      @showSearch="onShowSearch()"
    />
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
const IconSearch = defineAsyncComponent(() =>
  import("../../../icons/icons/IconSearch" /* webpackChunkName: "icon-search" */)
);
const IconMenu = defineAsyncComponent(() =>
  import("../../../icons/icons/IconMenu" /* webpackChunkName: "icon-menu" */)
);
const IconClose = defineAsyncComponent(() =>
  import("../../../icons/icons/IconClose" /* webpackChunkName: "icon-close" */)
);
const Search = defineAsyncComponent(() =>
  import("../../../../../../SearchBundle/assets/js/components/common/Search/Search" /* webpackChunkName: "search" */)
);

export default {
  name: "MobileNavigationButtons",
  components: {
    Search,
    IconSearch,
    IconClose,
    IconMenu
  },
  data() {
    return {
      showSearch: false,
      open: false
    };
  },
  mounted() {
  },
  methods: {
    onShowSearch() {
      this.showSearch = !this.showSearch;
    },
    toggleMobileMenu() {
      const el = document.getElementById('mobileNavigation');
      if (el.classList.contains('hidden')) {
        this.open = true;
        el.classList.remove('hidden');
      } else {
        this.open = false;
        el.classList.add('hidden');
      }
    }
  },
};
</script>
