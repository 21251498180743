import vueDebounce from 'vue-debounce'
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n/index';
import de from '../i18n/de.json';
import MobileNavigationButtons from '../components/common/Content/MobileNavigationButtons';

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de: de
  },
})

let app=createApp(MobileNavigationButtons)
app.use(vueDebounce, {
  listenTo: ['input', 'keyup']
})
app.use(i18n);
app.mount("#mobile-navigation-buttons")
